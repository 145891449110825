<template>
  <div class="pa-5">
    <v-app>
      <v-row>
        <v-col cols="12" md="6" class="mb-4" style="background-color: #212529">
          <v-row>
            <v-col cols="6">
              <v-menu
                ref="dateOpen"
                v-model="dateOpen"
                :close-on-content-click="false"
                :return-value.sync="start"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="start"
                    dark
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    dense
                    readonly
                    outlined
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker dark v-model="start" no-title>
                  <v-spacer />
                  <v-btn text dark color="primary" @click="dateOpen = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    dark
                    color="primary"
                    @click="$refs.dateOpen.save(start)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="type"
                :items="typeOptions"
                label="Type"
                class="my-auto"
                dark
                hide-details
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-sheet height="500">
            <v-calendar
              trim-weeks
              ref="calendar"
              :start="start"
              :type="type"
              dark
              @click:date="open"
              :events="events"
            ></v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
      <v-dialog max-width="600px" persistent v-model="dialog">
        <v-card>
          <v-card-title>
            <h3>일정 추가</h3>
          </v-card-title>
          <v-card-text>
            <v-form class="px-3" ref="form">
              <v-text-field
                label="일정"
                v-model="calendar.title"
                prepend-icon="mdi-folder-marker"
              ></v-text-field>
              <v-textarea
                label="상세설명"
                v-model="calendar.content"
                prepend-icon="mdi-pencil"
              ></v-textarea>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        slot="activator"
                        label="시작일"
                        readonly
                        prepend-icon="mdi-calendar-month"
                        v-on="on"
                        :value="calendar.startDate"
                        class=""
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="calendar.startDate"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="startTimer"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="시작 시간"
                        readonly
                        :value="calendar.startTime"
                        prepend-icon="mdi-timer"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="startTimer"
                      v-model="calendar.startTime"
                    >
                      <v-btn class="mx-auto" @click="selectTime">선택 </v-btn>
                    </v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" class="pt-0">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        slot="activator"
                        label="종료일"
                        readonly
                        prepend-icon="mdi-calendar-month"
                        v-on="on"
                        :value="calendar.endDate"
                        class=""
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="calendar.endDate"
                      :allowed-dates="allowedDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="endTimer"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="종료 시간"
                        readonly
                        :value="calendar.endTime"
                        prepend-icon="mdi-timer"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="endTimer" v-model="calendar.endTime">
                      <v-btn class="mx-auto" @click="selectTime">선택 </v-btn>
                    </v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <div class="text-center">
                <v-btn
                  text
                  class="primary white--text mx-2 mt-3"
                  @click="submit"
                >
                  추가
                </v-btn>
                <v-btn
                  text
                  class="primary white--text mx-2 mt-3"
                  @click="close"
                >
                  닫기
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dateOpen: false,
      start: this.$moment().format("YYYY-MM-DD"),
      type: "month",
      typeOptions: [
        { text: "Day", value: "day" },
        { text: "Week", value: "week" },
        { text: "Month", value: "month" },
      ],
      calendar: {
        title: "",
        content: "",
        startDate: "",
        startTime: "",
        dialog: false,
      },
      startTimer: false,
      endTimer: false,

      events: [
        {
          name: "Weekly Meeting",
          start: "2021-06-07 09:00",
          end: "2021-06-11 10:00",
          color: "red",
        },
        {
          name: `Thomas' Birthday`,
          start: "2021-06-1",
          end: "2021-06-10 10:00",
          color: "blue",
        },
        {
          name: "Mash Potatoes",
          start: "2021-06-09 12:30",
          end: "2021-06-09 15:30",
          color: "green",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("util", ["GET_DATE_INFO"]),
    dialog() {
      //console.log("dialog");
      this.calendar.dialog = this.GET_DATE_INFO.dialog;
      return this.calendar.dialog;
    },
    calendar() {
      //console.log("cal");
      this.calendar = this.GET_DATE_INFO;
      return this.calendar;
    },
  },
  updated() {},
  methods: {
    ...mapMutations("util", ["CLOSE_CALENDAR_DIALOG", "REQUEST_ADD_EVENT"]),
    ...mapActions("util", ["STORE_DATE_INFO"]),
    open(date) {
      //console.log("open");
      //console.log(date);
      this.STORE_DATE_INFO(date);
      this.calendar = this.GET_DATE_INFO;
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.REQUEST_ADD_EVENT(this.calendar);
        //this.$store.util.commit("REQUEST_ADD_EVENT", this.calendar);
      }
    },
    close() {
      //console.log("asd");
      this.CLOSE_CALENDAR_DIALOG();
      //this.$store.util.commit("CLOSE_CALENDAR_DIALOG");
    },
    selectTime() {
      this.endTimer = false;
      this.startTimer = false;
    },
    allowedDates(val) {
      let endDate = val.split("-").reduce((a, b) => a + b);
      let startDate = this.calendar.startDate
        .split("-")
        .reduce((a, b) => a + b);
      return endDate >= startDate;
    },
  },
};
</script>

<style>
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
